import React, { useState } from "react";
import { ThankYouPopup } from "../components";
import { logoImg, check, cancel } from "../assets";
import { FaLinkedinIn, FaEnvelope, FaTwitter } from "react-icons/fa";

const Footer = () => {
  const [popupVisible, setPopupVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const socialLinks = {
    linkedin: "https://www.linkedin.com/in/jalajboy/",
    email: "mailto:arvind@parikshalabs.com",
    twitter: "https://twitter.com/jalajboy",
  };

  const validateEmail = (email) => {
    if (!email) return true;
    const emailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return emailPattern.test(email);
  };

  const handleSubscribeClick = async () => {
    if (email) {
      if (validateEmail(email)) {
        const formData = new FormData();
        formData.append('email', email);
        formData.append('timestamp', new Date().toLocaleString());

        try {
          const response = await fetch('https://script.google.com/macros/s/AKfycbyorGfU8MdXc5W8s9pS4UPJ221hRZfNGRQQiBz4xR8ZmuG3rCLYkqv5FpCN_2_zTZTl/exec', {
            method: 'POST',
            body: formData,
          });
          const json = await response.json(); // Parse the response as JSON
          console.log('Server response:', json); // Log the server response
          if (json.result === 'success') { // Check the 'result' property of the JSON object
            setPopupVisible(true);
          } else {
            setEmailError('Failed to submit form. Please try again.');
          }
        } catch (error) {
          setEmailError('Failed to submit form. Please try again.');
        }
      } else {
        setEmailError("Please enter a valid email address");
      }
    } else {
      setEmailError("Please enter an email address");
    }
  };

  const handleEmailChange = (e) => {
    const enteredEmail = e.target.value;
    setEmail(enteredEmail);

    if (!validateEmail(enteredEmail)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
  };

  const inputStyles = {
    backgroundColor: validateEmail(email) ? (email ? "#F2F3F4" : "transparent") : "#FEE2E2",
    border: validateEmail(email) ? (email ? "1px solid #01cc40" : "1px solid grey") : "1px solid red",
    backgroundImage: validateEmail(email) ? (email ? `url(${check})` : "") : `url(${cancel})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right 10px center",
  };

  return (
    <div className="w-full bg-pink-200 pt-10 pb-3 px-4 md:px-10">
      <div className="md:max-w-[1480px] mx-auto flex flex-col md:flex-row gap-3 md:px-10">
        <div className="flex-grow mb-8 md:mb-0">
          <img src={logoImg} className="h-[24px]" alt="Logo" />
          <h3 className="text-2xl font-bold mt-4">Contact Us</h3>
          <div className="flex flex-wrap gap-4 py-4 pr-7">
            <a
              href={socialLinks.linkedin}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="p-4 bg-pink-700  hover:bg-pink-500 transition-colors duration-200 rounded-xl">
                <FaLinkedinIn size={25} style={{ color: "#E9F8F3" }} />
              </div>
            </a>
            <a href={socialLinks.email}>
              <div className="p-4 bg-pink-700  hover:bg-pink-500 transition-colors duration-200 rounded-xl">
                <FaEnvelope size={25} style={{ color: "#E9F8F3" }} />
              </div>
            </a>
            <a
              href={socialLinks.twitter}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="p-4 bg-pink-700  hover:bg-pink-500 transition-colors duration-200 rounded-xl">
                <FaTwitter size={25} style={{ color: "#E9F8F3" }} />
              </div>
            </a>
          </div>
        </div>

        <div className="flex-grow">
          <h3 className="text-2xl font-bold">Subscribe</h3>
          <h3 className="py-2 font-semibold text-[#6D737A]">
            Subscribe to our quarterly newsletter on SaaS trends and case studies.
          </h3>

          <div className="relative">
            {popupVisible && <ThankYouPopup closePopup={() => {setPopupVisible(false); setEmail(""); setEmailError("");}} />}
            <input
              id="email"
              name= "email"
              style={inputStyles}
              className="p-4 w-full rounded outline-none"
              placeholder="Enter Your Email"
              value={email}
              onChange={handleEmailChange}
            />
            {emailError && <p className="text-red-500 text-sm">{emailError}</p>}
            <button
              onClick={handleSubscribeClick}
              className={`bg-primary text-white px-4 py-2 my-4 rounded-lg hover:bg-button hover:text-primary leading-17 text-sm transition duration-300 ease-in-out`}
            >
              Subscribe Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;