import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Navbar, Footer } from "./components";
import { Home, Mentoring, RevenueAcceleration, FundRaising } from "./pages";
import ScrollToTop from './components/ScrollToTop'

function App() {
    return (
        <Router>
               <ScrollToTop />
            <Navbar />
            <Routes>
                <Route path="/" element={<Home />}></Route>
                <Route path="/mentoring" element={<Mentoring />}></Route>
                <Route path="/fund-raising" element={<FundRaising />}></Route>
                <Route path="/revenue-acceleration" element={<RevenueAcceleration />}></Route>
            </Routes>
            <Footer />
        </Router>
    );
}

export default App;
