import React from "react";

const RevenueAcceleration = () => {
  return (
    <div className="flex flex-col items-center justify-center mt-[70px] md:mt-20 md:mb-12 min-h-screen text-gray-800">
      <div className="text-center">
        <h1 className="text-4xl font-bold mb-4 animate-pulse">Revenue Acceleration</h1>
        <p className="text-lg mb-8 px-4">
          I specialize in driving revenue growth for SaaS companies by optimizing sales strategies, enhancing customer acquisition, and implementing effective pricing models. My insights help businesses streamline their processes, improve conversion rates, and achieve sustainable profitability.
        </p>
      </div>
      <div className="w-full max-w-2xl mx-auto">
        <div className="text-center">
          <h2 className="text-2xl font-bold mb-2 animate-pulse">Schedule a Meeting</h2>
          <p className="mb-4">Schedule a meeting with our expert. (known as top global SaaS influencer for 2020 & 2023.)</p>
        </div>
        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-16">
          <div className="w-full md:w-1/2 p-4 bg-gray-100 rounded-md">
            <h3 className="text-xl font-bold mb-2 text-center animate-pulse">Paid Session</h3>
            <iframe 
              className="w-full h-[500px]" 
              src="https://calendly.com/jalajboy/paid-consulting-session-discounted" 
              style={{minWidth: '320px'}} 
              scrolling="no" 
              frameBorder="0">
            </iframe>
          </div>
          <div className="w-full md:w-1/2 p-4 bg-gray-100 rounded-md">
            <h3 className="text-xl font-bold mb-2 text-center animate-pulse">Free Session</h3>
            <iframe 
              className="w-full h-[500px]" 
              src="https://calendly.com/jalajboy/freeadvisory" 
              style={{minWidth: '320px'}} 
              scrolling="no" 
              frameBorder="0">
            </iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RevenueAcceleration;