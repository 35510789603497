import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { logoImg } from "../assets";
// import Button from "./Navbar/Button";
// import { FaBars, FaTimes } from "react-icons/fa";
// import MobileNavbar from "./Navbar/MobileNavbar";
// import Calendly from "./Calendly";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const Navbar = () => {
  // const [open, setOpen] = useState(false);
  // const [showCalendly, setShowCalendly] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const element = ref.current;
    const mq = window.matchMedia("(max-width: 48em)");

    const animationOptions = {
      position: "fixed",
      top: "1rem",
      left: "0.5rem",
      right: "0.5rem",
      padding: "0.5rem 0.5rem",
      borderRadius: "50px",
      border: "3px solid #ffffff",
      duration: 1,
      ease: "power1.out",
      scrollTrigger: {
        trigger: element,
        start: mq.matches ? "bottom+=200 top" : "bottom+=300 top",
        end: mq.matches ? "+=100" : "+=250",
        scrub: true,
      },
    };

    gsap.fromTo(element, animationOptions, animationOptions);

    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  // const openCalendly = () => {
  //   setShowCalendly(true);
  // };

  // const hideCalendly = () => {
  //   setShowCalendly(false);
  // };

  return (
    <nav className="bg-[rgba(255,255,255,0.85)] shadow-md py-2 w-11/12 m-auto relative z-10" ref={ref}>
      <div className="px-2 flex items-center font-medium justify-between">
        <div className="w-24">
          <Link className="inline-block w-24" to="/" >   {/* onClick={hideCalendly} */}
            <img
              src={logoImg}
              alt="Parikshalabs"
              className="md:cursor-pointer inline-block md:ml-2 md:mb-1 transform transition duration-500 ease-in-out hover:scale-110 hover:bg-gradient-to-r hover:from-pink-200 hover:to-pink-500"
            />
          </Link>
        </div>
        {/* <div className="hidden md:block">
          <Button onClick={openCalendly} />
        </div>
        <div
          className="text-xl block md:hidden"
          onClick={() => setOpen(!open)}
        >
          {open ? <FaTimes /> : <FaBars />}
        </div> */}
      </div>
      {/* {open && (
        <div className="block md:hidden absolute">
          <MobileNavbar />
        </div>
      )}
      <Calendly isOpen={showCalendly} onClose={hideCalendly} /> */}
    </nav>
  );
};

export default Navbar;