import React from "react";
import sliderImage2019 from "../assets/2019.png";
import sliderImage2020 from "../assets/2020.png";
import sliderImage2022 from "../assets/2022.png";
import sliderImage2023 from "../assets/2023.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Hero = () => {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
    };
    return (
        <div className="w-full  p-4 md:p-0 sm:p-8 md:pt-24 md:mb-0 mb-10">
            <div className="max-w-[1480px] m-auto  flex flex-col md:flex-row items-start justify-center md:gap-32">
                <div className="w-full md:w-1/2 order-1 md:order-2 mb-6 md:mb-6 my-16 md:my-0 relative group">
                    <div className="relative w-full md:w-[100%]">
                        <Slider {...settings} className="absolute  top-0 right-0 md:top-[65px] md:right-[40px] w-full h-full">
                        <div>
    <img src={sliderImage2019} className="w-full h-full object-cover rounded-2xl" alt="sliderImage2019" />
</div>
<div>
    <img src={sliderImage2020} className="w-full h-full object-cover rounded-2xl" alt="sliderImage2020" />
</div>
                            <div>
                                <img src={sliderImage2022} className="w-full h-full object-contain rounded-2xl" alt="sliderImage2022" />
                            </div>
                            <div>
                                <img src={sliderImage2023} className="w-full h-full object-contain rounded-2xl" alt="sliderImage2023" />
                            </div>
                        </Slider>
                    </div>
                </div>
                <div className="flex flex-col  justify-start  gap-4 w-full md:w-1/2 pr-0  order-2 md:order-1 md:ml-32 animate-fade-in">
                    <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold text-pink-700 mb-4 hover:text-pink-500 transition-colors duration-200">We are Pioneers and Thought Leaders in B2B SAAS Revenue Acceleration.</h1>
                    <h2 className="text-xl sm:text-2xl md:text-3xl font-medium text-gray-700 mb-4 hover:text-gray-500 transition-colors duration-200">Our Founder was named as a top global SaaS influencer for 2019, 20, 22 & 23.</h2>
                    <p className="text-base sm:text-lg md:text-xl text-gray-600 mb-4 hover:text-gray-500 transition-colors duration-200">We help young SAAS startups grow revenue from a very early stage to $1 mi ARR and scale from $1 mi ARR to $10 mi ARR through our all-round consulting services.</p>
                    <h3 className="text-lg sm:text-xl md:text-2xl font-bold text-pink-700 hover:text-pink-500 transition-colors duration-200">Grow Your SaaS Business With Us.🚀🌱</h3>
                </div>
            </div>
        </div>
    );
};

export default Hero;

