import { Tick } from "../assets";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaInstagram,
  FaWhatsapp,
  FaEnvelope,
  FaTwitter,
} from "react-icons/fa";

const socialLinks = {
  facebook: "https://www.facebook.com/arvind.jha.5661",
  linkedin: "https://www.linkedin.com/in/jalajboy/",
  instagram: "https://www.instagram.com",
  whatsapp: "https://api.whatsapp.com/send?phone=123456789",
  email: "mailto:arvind@parikshalabs.com",
  twitter: "https://twitter.com/jalajboy",
};

const ThankYouPopup = ({ closePopup }) => {
  return (
    <div className="bg-white flex flex-col items-center absolute bottom-28 shadow-xl w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
      <div className="mx-auto mt-[-50px]">
        <img src={Tick} alt="Tick" className="w-24 mx-auto" />
      </div>
      <div className="mx-auto md:text-center">
        <h2 className="text-4xl font-semibold">Thank You!</h2>
      </div>
      <p className="font-bold text-center mx-auto">
        Please follow us on social <span className="inline-block">media:</span>
      </p>

      <div className="flex flex-wrap items-center justify-center mx-auto">
        <div className="flex flex-wrap items-center justify-center mx-auto">
        <a
            href={socialLinks.facebook}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="p-2 bg-[#E9F8F3] rounded-xl">
              <FaFacebookF size={10} style={{ color: "#ec4899" }} />
            </div>
          </a>
          <a
            href={socialLinks.linkedin}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="p-2 bg-[#E9F8F3] rounded-xl">
              <FaLinkedinIn size={10} style={{ color: "#ec4899" }} />
            </div>
          </a>
          <a
            href={socialLinks.instagram}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="p-2 bg-[#E9F8F3] rounded-xl">
              <FaInstagram size={10} style={{ color: "#ec4899" }} />
            </div>
          </a>
          <a
            href={socialLinks.whatsapp}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="p-2 bg-[#E9F8F3] rounded-xl">
              <FaWhatsapp size={10} style={{ color: "#ec4899" }} />
            </div>
          </a>
          <a href={socialLinks.email}>
            <div className="p-2 bg-[#E9F8F3] rounded-xl">
              <FaEnvelope size={10} style={{ color: "#ec4899" }} />
            </div>
          </a>
          <a
            href={socialLinks.twitter}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="p-2 bg-[#E9F8F3] rounded-xl">
              <FaTwitter size={10} style={{ color: "#ec4899" }} />
            </div>
          </a>
        </div>

        <div className="flex flex-wrap items-center justify-center mx-auto">
          <p className="flex-grow font-medium text-center">
            <span className="inline-block">
              Your Mail has been successfully!
              <span className="inline-block">Submitted Thanks!</span>
            </span>
          </p>
        </div>
        <div className="flex flex-wrap items-center justify-center mx-auto">
          <button
            type="button"
            className={`bg-primary text-white text-center mx-auto my-4 px-5 py-2 rounded-lg hover:bg-pink-300 hover:text-primary leading-17 text-sm cursor-pointer transition duration-300 ease-in-out`}
            onClick={closePopup}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export default ThankYouPopup;
