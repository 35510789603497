import React from "react";
import { MetaTags } from "../components";
import { Hero, Services, Company} from "../components";

const Home = () => {
  const ogTitle = "Pariksha Labs - Home Page";
  const ogDescription = "Welcome to Pariksha Labs";
  const ogImage = "public/logo192.png";

  return (
    <div className="bg-pink-200">
      <MetaTags title={ogTitle} description={ogDescription} image={ogImage} />
      <Hero />
      <Services />
      <Company />
      {/* <Feedback /> */}
    </div>
  );
};

export default Home;
