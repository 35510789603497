import React from "react";
import "./Company.css";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import {
  companyLogo1,
  companyLogo2,
  companyLogo3,
  companyLogo4,
  companyLogo5,
  companyLogo6,
  companyLogo7,
  companyLogo8,
  companyLogo9,
  companyLogo10,
  companyLogo11,
  companyLogo12,
  companyLogo13,
  companyLogo14,
  companyLogo15,
  companyLogo16,
  companyLogo17,
  companyLogo18,
} from "../../assets";

const Company = () => {
  const companyLogos = [
    companyLogo1,
    companyLogo2,
    companyLogo3,
    companyLogo4,
    companyLogo5,
    companyLogo6,
    companyLogo7,
    companyLogo8,
    companyLogo9,
    companyLogo10,
    companyLogo11,
    companyLogo12,
    companyLogo13,
    companyLogo14,
    companyLogo15,
    companyLogo16,
    companyLogo17,
    companyLogo18,
  ];
  return (
    <div className="maincontainer">
      <div className="company-info">
        <h1>Trusted by Leading Companies Globally <span>&rarr;</span> Startup Portfolio</h1>
        <p>
          Discover why these forward-thinking companies rely on our solutions
          for their success.
        </p>
      </div>
      <AliceCarousel 
  autoPlay 
  autoPlayInterval={2000} 
  infinite
  responsive={{
    0: { items: 1 },
    1024: { items: 4 },
  }}
>
  {companyLogos.map((logo, index) => (
    <div className="card" key={index}>
      <img src={logo} alt="" />
      <div className="bar">
        <div className="emptybar"></div>
        <div className="filledbar"></div>
      </div>
      <div className="circle">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
          <circle className="stroke" cx="60" cy="60" r="50" />
        </svg>
      </div>
    </div>
  ))}
</AliceCarousel>
    </div>
  );
};

export default Company;