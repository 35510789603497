import React from "react";
import { Link } from "react-router-dom";
const ServiceContainer = ({ imageUrl, title, link, description }) => {
    return (
        <div className="w-full md:w-1/2 lg:w-1/3 p-4 relative group">
            <Link to={link} className="mx-2 md:mx-4">
                <div className="bg-pink-100 rounded-lg flex flex-col h-full overflow-hidden relative">
                    <img src={imageUrl} alt={title} className="w-full h-32 md:h-40 object-contain transition-transform duration-300 ease-in-out transform scale-100 group-hover:scale-110" />

                    <div className="p-4 flex-grow flex flex-col justify-center items-center">
                        <div className="text-center">
                            <h3 className="text-lg md:text-2xl font-bold mb-2">
                                <a href={link}>{title}</a>
                            </h3>
                            <p className="text-gray-700">{description}</p>
                        </div>
                        {/* <Link  to={link} className="mx-2 md:mx-4">  */}
                        <button className={`bg-primary text-white px-4 py-2 my-4 md:px-8 rounded-lg hover:bg-button hover:text-primary leading-17 text-sm md:text-base transition duration-300 ease-in-out`}>Explore More</button>
                        {/* </Link> */}
                    </div>
                    <div className="mx-2 md:mx-4 transition-transform duration-300 ease-in-out transform origin-left absolute bottom-0 left-0 right-0 h-1 bg-primary scale-x-0 group-hover:scale-x-100"></div>
                </div>
            </Link>
        </div>
    );
};

const Services = () => {
    return (
        <div className="flex flex-wrap justify-center items-center mx-4 md:mx-32 my-9">
            <ServiceContainer
                imageUrl="https://techaxlabs.com/wp-content/uploads/2023/03/1-01.png"
                title="• Mentoring"
                link="/mentoring"
                description="Embarking on the journey of creating a SaaS application requires
        strategic guidance at every turn, and a mentor can be instrumental in shaping your path to success. Starting with a pitch deck review, a mentor can offer constructive feedback to ensure your presentation succinctly conveys the uniqueness and potential of your product."
            />

            <ServiceContainer
                imageUrl="https://techaxlabs.com/wp-content/uploads/2023/03/1-03.png"
                title="• Revenue Acceleration"
                link="/revenue-acceleration"
                description="Accelerating revenue from zero to $100k, then from $100k to $1
        million, and ultimately reaching $10 million involves a strategic
        focus on both sales and product development. In the sales and
        go-to-market (GTM) domain, the key lies in effective customer
        acquisition."
            />

            <ServiceContainer
                imageUrl="https://techaxlabs.com/wp-content/uploads/2023/03/1-02.png"
                title="• Fund Raising"
                link="/fund-raising"
                description="Successfully raising funds for your SaaS venture involves a
        strategic approach across various dimensions. Crafting a robust
        business plan and revenue forecast modeling are foundational
        steps, and a mentor can provide invaluable insights into creating
        a compelling narrative that aligns with investor expectations."
            />
        </div>
    );
};

export default Services;
